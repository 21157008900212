.App {
	text-align: center;
	background-size: contain;
	min-height: 100vh;
	overflow: hidden;
}
.bodyMain {
	max-width: 1100px;
	margin: 0 auto;
}
.hr {
	height: 30px;
	opacity: 1;
	background-color: var(--color1st);
	-webkit-mask: url(./files/hr.svg) 50% 0 no-repeat;
	mask: url(./files/hr.svg) 50% 0 no-repeat;
}
.hr2 {
	height: 30px;
	opacity: 1;
	background-color: var(--color1st);
	-webkit-mask: url(./files/hr2.svg) 50% 0 no-repeat;
	mask: url(./files/hr2.svg) 50% 0 no-repeat;
}
.hr3 {
	height: 30px;
	opacity: 1;
	background-color: var(--color1st);
	-webkit-mask: url(./files/hr3.svg) 50% 0 no-repeat;
	mask: url(./files/hr3.svg) 50% 0 no-repeat;
}
.hr4 {
	height: 30px;
	opacity: 1;
	background-color: var(--color1st);
	-webkit-mask: url(./files/hr4.svg) 50% 0 no-repeat;
	mask: url(./files/hr4.svg) 50% 0 no-repeat;
}
.profilePhoto {
	box-shadow: 0px 2px 7px 0px #000000;
	border: 1px solid var(--colorborder);
}
@font-face {
	font-family: "Light";
	src: local("Amsterdam"), url("./Amsterdam.ttf") format("truetype");
}
.title span {
	font-family: "Light" !important;
	font-weight: bold;
}

#borderChoose {
	border: 1px solid var(--colorborder);
}
#borderChoose .hr,
#borderChoose .hr2,
#borderChoose .hr3,
#borderChoose .hr4 {
	width: 100%;
}
.colorChange .Mui-selected {
	background-color: var(--color1st);
}

.MuiInputBase-formControl::before,
.MuiInputBase-formControl::after,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border: 3px solid var(--colorborder) !important;
}
.Mui-focused,
.MuiInputLabel-root,
.MuiOutlinedInput-input {
	color: var(--color1st) !important;
	font-size: 21px !important;
	border-color: var(--colorborder) !important;
}
.MuiInputLabel-root {
	background-color: var(--color1st) !important;
	color: white !important;
	padding: 3px !important;
	border-radius: 15px !important;
	padding: 0 15px !important;
	border-color: var(--colorborder) !important;
}

.login .MuiButtonBase-root,
.toggle .MuiButtonBase-root,
.share .MuiButtonBase-root,
.contact .MuiButtonBase-root,
.icons .MuiButtonBase-root,
.bg .MuiButtonBase-root,
.addVideo .MuiButtonBase-root,
.mails .MuiButtonBase-root,
.reviews .MuiButtonBase-root,
.manageMap .MuiButtonBase-root,
.details .MuiButtonBase-root,
.buttonsLink .MuiButtonBase-root,
.headlines .MuiButtonBase-root,
.colorChange .MuiButtonBase-root,
.profilePhotos .MuiButtonBase-root,
.galleryPhotos .MuiButtonBase-root,
.bannerPhotos .MuiButtonBase-root {
	background-color: var(--color1st);
	color: white;
	font-weight: 900 !important;
	font-size: 1.1rem;
	border-color: var(--colorborder) !important;
}
.login .MuiButtonBase-root:hover,
.toggle .MuiButtonBase-root:hover,
.share .MuiButtonBase-root:hover,
.contact .MuiButtonBase-root:hover,
.icons .MuiButtonBase-root:hover,
.bg .MuiButtonBase-root:hover,
.addVideo .MuiButtonBase-root:hover,
.mails .MuiButtonBase-root:hover,
.reviews .MuiButtonBase-root:hover,
.manageMap .MuiButtonBase-root:hover,
.details .MuiButtonBase-root:hover,
.buttonsLink .MuiButtonBase-root:hover,
.headlines .MuiButtonBase-root:hover,
.colorChange .MuiButtonBase-root:hover,
.profilePhotos .MuiButtonBase-root:hover,
.galleryPhotos .MuiButtonBase-root:hover,
.bannerPhotos .MuiButtonBase-root:hover {
	background-color: var(--color1st);
	color: white;
}
.dashboard .MuiButtonBase-root {
	background-color: var(--color1st);
	color: white;
}
.dashboard .MuiButtonBase-root:hover {
	background-color: white;
	color: var(--color1st);
	border: 1px solid var(--colorborder);
}
.dashboard .MuiButtonBase-root:hover .fi {
	background-color: var(--color1st);
	color: white;
}

.dashboard .activeButton {
	background-color: white;
	color: var(--color1st);
	border: 1px solid var(--colorborder);
}

.dashboard .activeButton .MuiListItemIcon-root {
	color: var(--color1st);
}
.MuiButtonBase-root:hover .MuiListItemIcon-root {
	color: var(--color1st);
}
.MuiTableCell-head {
	color: var(--color1st) !important;
}
.dashboard .MuiDrawer-paper {
	background-color: var(--color1st);
}
.swiper-pagination-bullet-active {
	background-color: var(--color1st) !important;
}

.textColor,
.MuiFormHelperText-root {
	color: var(--color1st) !important;
}
input.Mui-disabled,
.MuiOutlinedInput-root .Mui-disabled {
	-webkit-text-fill-color: var(--color1st) !important;
	color: var(--color1st) !important;
	border-color: var(--colorborder) !important;
}
.borderColor {
	border-color: var(--colorborder) !important;
}
.bgColor {
	background-color: var(--color1st) !important;
}

.loader span {
	background: var(--color1st);
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.MuiRating-root {
	color: var(--color1st) !important;
}
.MuiRating-iconEmpty {
	color: gray !important;
}
.dashboard .MuiListItem-root {
	border: 1px solid var(--colorborder);
}

.react-player {
	height: auto !important;
	aspect-ratio: 16/9 !important;
	overflow: hidden !important;
	background-color: black !important;
}

.share .MuiButtonBase-root {
	opacity: 40%;
	cursor: pointer;
	z-index: 999;
}

.share .MuiButtonBase-root:hover {
	opacity: 100%;
	background-color: transparent;
}

.share .MuiButtonBase-root {
	animation: zoom 2s linear infinite;
}

@keyframes zoom {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}
.swal2-container {
	z-index: 9999;
}
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-thumb {
	background: var(--color1st);
}
.home {
	height: 100vh;
}
.marvel-device.note8 {
	margin: 0 auto !important;
	transform: scale(0.67) !important;
	-webkit-transform: scale(0.67) !important;
	transform-origin: 0 0 !important;
	-webkit-transform-origin: 0 0 !important;
	display: block !important;
}
.note8 .screen {
	background: white !important;
}
.marvel-device.note8 .shadow {
	box-shadow: inset 0 0 0 0 white, inset 0 0 30px 0 rgb(255 255 255 / 50%),
		0 0 0px 0 white, 0 0 0 0 rgb(255 255 255 / 50%) !important;
}
.MuiPaginationItem-root.Mui-selected {
	color: var(--color1st) !important;
	background-color: white !important;
}
.MuiPaginationItem-root {
	border-color: var(--colorborder) !important;
}

.profilePhoto {
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}
@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
}
.details .MuiAccordion-root {
	animation: zooms 5s linear infinite;
}
.details .MuiAccordionDetails-root {
	color: var(--color1st);
}
.details .MuiSvgIcon-root {
	color: white;
}
.details .MuiSvgIcon-root:hover {
	color: var(--color1st);
}
.details .MuiAccordionSummary-root {
	background: var(--color1st);
	color: white;
}

.z1 .MuiButtonBase-root,
.z1 .MuiTypography-root {
	animation: zooms 2s linear infinite;
}
.z2 .MuiButtonBase-root,
.z2 .MuiTypography-root {
	animation: zooms 1.5s linear infinite;
}
.z3 .MuiButtonBase-root,
.z3 .MuiTypography-root {
	animation: zooms 1.9s linear infinite;
}

.z4 .MuiButtonBase-root,
.z4 .MuiTypography-root {
	animation: zooms 1.7s linear infinite;
}
@keyframes zooms {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.05);
	}

	100% {
		transform: scale(1);
	}
}
.form-control::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #999;
}

.form-control::-webkit-input-placeholder {
	color: #999;
}
